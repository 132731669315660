/*#region paddingTop*/

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-35 {
    padding-top: 35px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-100 {
    padding-top: 100px;
}

/*#endregion paddingTop*/

/*#region paddingBottom*/
.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-35 {
    padding-bottom: 35px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}
/*#endregion paddingBottom*/

/*#region paddingY*/

.padding-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.padding-y-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.padding-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.padding-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*#endregion paddingY*/

/*#region paddingX*/

.padding-x-10p {
    padding: 0 10%;
}

.padding-x-10 {
    padding: 0 10px;
}

.padding-x-15 {
    padding: 0 15px;
}

.padding-x-20 {
    padding: 0 20px;
}



/*#endregion paddingX*/

/*#region marginTop*/

.margin-top-0 {
    margin-top: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}


.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

/*#endregion marginTop*/

/*#region marginBottom*/
.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}
/*#endregion marginBottom*/

/*#region marginY*/

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.margin-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}


.margin-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.margin-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.margin-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.margin-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.margin-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

/*#endregion marginY*/

.white {
    color: #FFF;
}

.black {
    color: #000;
}

.container-bg-white {
    background-color: #fff;
}

.uppercase {
    text-transform: uppercase;
}

.relative {
    position: relative;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

img.HoverBounce,
.HoverBounce img {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    display: block;
    overflow: hidden;
}

    img.HoverBounce :hover,
    .HoverBounce img :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

a.HoverBounce {
    display: block;
}

    a.HoverBounce img {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        display: block;
        overflow: hidden;
    }

    a.HoverBounce :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

/*#region fonts*/
.OpenSans,
.BodyFont {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.OpenSansBold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}
.PTSansNarrowBold {
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700;
}
@font-face {
  font-family: 'Hvd_comic_serif_pro';
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/hvd_comic_serif_pro.eot');
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/hvd_comic_serif_pro.eot?#iefix') format('embedded-opentype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/hvd_comic_serif_pro.woff2') format('woff2'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/hvd_comic_serif_pro.woff') format('woff'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/hvd_comic_serif_pro.ttf') format('truetype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/hvd_comic_serif_pro.svg#hvd_comic_serif_pro') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.HvdComicSerifPro {
  font-family: 'Hvd_comic_serif_pro', sans-serif;
  font-weight: normal !important;
}
@font-face {
  font-family: 'Bjola';
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.eot');
  src: url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.eot?#iefix') format('embedded-opentype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.woff2') format('woff2'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.woff') format('woff'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.ttf') format('truetype'), url('https://bouncycastlenetwork-res.cloudinary.com/raw/upload/fonts/bjola.svg#bjola') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.Bjola {
  font-family: 'Bjola', sans-serif;
  font-weight: normal;
}
html {
  font-size: 16px;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
.nicefont {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}
h1,
h2,
h3,
h4 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}
/*#endregion fonts*/
.textContent {
  font-size: 1rem;
}
@media (max-width: 767px) {
  .textContent li a {
    padding: 8px 0;
    margin-bottom: 10px;
    display: inline-block;
  }
}
.textContent h1,
.lower-content h2 {
  font-family: 'Bjola', sans-serif;
  font-weight: normal;
  text-align: center;
  color: #1fc8e0;
  font-size: 2.3rem;
}
.textContent h1 {
  margin-top: 0;
}
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #005bff;
  background-color: #81d546;
  background: -webkit-gradient(linear, left top, left bottom, from(#81d546), to(#47b227));
  background: linear-gradient(to bottom, #81d546, #47b227);
  color: white;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 63px;
  text-align: right;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0 0;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
.outer-wrapper {
  max-width: 100vw;
  overflow: hidden;
  position: relative;
}
@media (min-width: 992px) {
  .outer-wrapper {
    padding: 0 10px 0;
  }
}
@media (min-width: 1200px) {
  .outer-wrapper {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .outer-wrapper--alt .header {
    padding-top: 23.52941176%;
  }
}
.outer-wrapper--alt .header__container {
  background: #fff;
}
.outer-wrapper--alt .category-links {
  margin-top: 0;
}
.sea-bg {
  position: absolute;
  bottom: 0;
  left: 0;
}
.bottom-sand {
  width: calc(100% + 30px);
  -webkit-transform: translateX(-15px);
          transform: translateX(-15px);
  max-width: none !important;
}
.container {
  position: relative;
  width: 100%;
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
    margin: 0 auto;
  }
}
.header {
  text-align: center;
  position: relative;
}
@media (max-width: 767px) {
  .header {
    padding-top: 80px;
    padding-bottom: 20px;
  }
}
@media (min-width: 768px) {
  .header {
    padding-top: 23.52941176%;
  }
}
.header__wrapper {
  position: relative;
}
@media (max-width: 767px) {
  .header__logo {
    display: block;
    margin: 0 auto 30px;
  }
}
@media (min-width: 768px) {
  .header__logo {
    position: absolute;
    width: 30.92%;
    bottom: 6.43%;
    left: 34.41%;
  }
}
@media (max-width: 767px) {
  .header__call {
    display: block;
    margin: 15px auto 0;
  }
}
@media (min-width: 768px) {
  .header__call {
    position: absolute;
    width: 25.21%;
    bottom: 51.43%;
    right: 1.01%;
  }
}
.header__phone {
  text-align: right;
}
@media (max-width: 767px) {
  .header__phone {
    margin: 15px auto 0;
  }
}
@media (min-width: 768px) {
  .header__phone {
    position: absolute;
    width: 29.41%;
    bottom: 34.57%;
    right: 0.42%;
  }
}
.header__email {
  text-align: right;
}
@media (max-width: 767px) {
  .header__email {
    display: block;
    margin: 15px auto 0;
  }
}
@media (min-width: 768px) {
  .header__email {
    position: absolute;
    width: 21.01%;
    bottom: 13.43%;
    right: 0.42%;
  }
}
@media (max-width: 767px) {
  .header__follow {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__follow {
    position: absolute;
    width: 25.21%;
    bottom: 58.21%;
    left: 1.01%;
  }
}
@media (max-width: 767px) {
  .header__follow {
    margin: 15px auto 0;
  }
}
.header__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 767px) {
  .header__social {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__social {
    position: absolute;
    width: 27.14%;
    bottom: 23.21%;
    left: 1.68%;
  }
}
@media (max-width: 767px) {
  .header__social {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.header__social-link {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 20%;
}
@media (max-width: 767px) {
  .header__social-link {
    width: 75px;
    margin: 0 5px;
  }
}
.banner #CarouselContainer {
  padding-top: 41.87%;
  overflow: hidden;
}
.banner #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.banner #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.banner #CarouselContainer .carousel-inner .item a img,
.banner #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
@media (max-width: 767px) {
  .banner__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.no-gutter {
  margin-left: 0;
  margin-right: 0;
}
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}
.product-search {
  background-color: #bfe600;
  background: -webkit-gradient(linear, left top, left bottom, from(#bfe600), to(#a3c400));
  background: linear-gradient(to bottom, #bfe600, #a3c400);
  -webkit-box-shadow: inset 0 -6px 0 0 rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 -6px 0 0 rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .product-search {
    padding: 20px !important;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 20px);
    text-align: center;
  }
}
@media (min-width: 768px) {
  .product-search:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 50%;
  }
}
.product-search:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  pointer-events: none;
}
.product-search .SearchBoxTextBox {
  border-radius: 8px;
  border: none;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
          box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}
@media (max-width: 767px) {
  .product-search .SearchBoxTextBox {
    height: 40px;
    border-radius: 8px;
    width: 100%;
    margin: 0 auto;
    max-width: 430px !important;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .product-search .SearchBoxTextBox {
    position: absolute;
    width: 79.39%;
    left: 13.51%;
    top: 51.54%;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    height: 27.33%;
    padding-left: 15%;
    z-index: 1;
  }
}
.product-search__text {
  z-index: 1;
}
@media (max-width: 767px) {
  .product-search__text {
    margin: 0 auto 15px;
  }
}
@media (min-width: 768px) {
  .product-search__text {
    position: absolute;
    width: 39.7%;
    left: 41.89%;
    top: 16.15%;
  }
}
.product-search__icon {
  z-index: 1;
}
@media (max-width: 767px) {
  .product-search__icon {
    display: none;
  }
}
@media (min-width: 768px) {
  .product-search__icon {
    position: absolute;
    width: 35.81%;
    left: 1.69%;
    top: 8.46%;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
}
.check-area {
  background-color: #bfe600;
  background: -webkit-gradient(linear, left top, left bottom, from(#bfe600), to(#a3c400));
  background: linear-gradient(to bottom, #bfe600, #a3c400);
  -webkit-box-shadow: inset 0 -6px 0 0 rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 -6px 0 0 rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .check-area {
    padding: 20px !important;
    margin-bottom: 10px;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 20px);
  }
}
@media (min-width: 768px) {
  .check-area:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 50%;
  }
}
.check-area:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
@media (max-width: 767px) {
  .check-area__inner {
    max-width: 450px;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .check-area__text {
    max-width: calc(100% - 10px) !important;
    margin: 0 auto;
    display: block;
  }
}
@media (min-width: 768px) {
  .check-area__text {
    position: absolute;
    width: 73.63%;
    left: 23.52%;
    top: 16.15%;
  }
}
.check-area__icon {
  z-index: 2;
}
@media (max-width: 767px) {
  .check-area__icon {
    max-width: 50px !important;
    display: none;
  }
}
@media (min-width: 768px) {
  .check-area__icon {
    position: absolute;
    width: 34.29%;
    left: 1.27%;
    top: 19.67%;
  }
}
.check-area #PostcodeSearchLeft select,
.check-area #PostcodeSearchLeft input {
  z-index: 1;
  font-size: 0.9rem;
  border-radius: 8px;
  border: none;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
          box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}
@media (max-width: 767px) {
  .check-area #PostcodeSearchLeft select,
  .check-area #PostcodeSearchLeft input {
    height: 40px;
    max-width: 430px !important;
    margin: 10px auto 0;
    text-align: center;
    position: relative;
    z-index: 1;
  }
}
@media (min-width: 768px) {
  .check-area #PostcodeSearchLeft select,
  .check-area #PostcodeSearchLeft input {
    position: absolute;
    width: 75%;
    top: 50%;
    left: 19%;
    height: 27.33%;
    padding-left: 15%;
  }
}
.check-area .PostcodeMessage {
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.check-availability {
  background-color: #bfe600;
  background: -webkit-gradient(linear, left top, left bottom, from(#bfe600), to(#a3c400));
  background: linear-gradient(to bottom, #bfe600, #a3c400);
  -webkit-box-shadow: inset 0 -6px 0 0 rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 -6px 0 0 rgba(0, 0, 0, 0.2);
  border-bottom-right-radius: 10px;
  position: relative;
  z-index: 2;
}
@media (max-width: 767px) {
  .check-availability {
    padding: 20px !important;
    border-radius: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 20px);
  }
}
@media (min-width: 768px) {
  .check-availability:before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 25%;
  }
}
@media (max-width: 767px) {
  .check-availability__inner {
    max-width: 450px;
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .check-availability__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media (max-width: 767px) {
  .check-availability__text {
    max-width: calc(100% - 10px) !important;
    margin: 0 auto;
  }
}
@media (min-width: 768px) {
  .check-availability__text {
    position: absolute;
    width: 72.22%;
    left: 20.67%;
    top: 16.15%;
  }
}
.check-availability__icon {
  z-index: 2;
}
@media (max-width: 767px) {
  .check-availability__icon {
    max-width: 45px !important;
    display: none;
    margin: 0 auto 15px;
  }
}
@media (min-width: 768px) {
  .check-availability__icon {
    position: absolute;
    width: 12.75%;
    left: 4.25%;
    top: 26.67%;
  }
}
.check-availability .SearchDate,
.check-availability .SearchCategory,
.check-availability .SearchButton {
  height: 40px;
  display: block;
  border: none;
  border-radius: 8px;
  -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
          box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}
@media (max-width: 767px) {
  .check-availability .SearchDate,
  .check-availability .SearchCategory,
  .check-availability .SearchButton {
    width: 100%;
    margin: 15px auto 0;
    max-width: 430px !important;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .check-availability .SearchDate,
  .check-availability .SearchCategory,
  .check-availability .SearchButton {
    position: absolute;
    top: 50%;
    left: 19%;
    height: 27.33%;
  }
}
.check-availability .SearchDate {
  font-size: 0.9rem;
}
@media (min-width: 768px) {
  .check-availability .SearchDate {
    width: 30%;
    left: 15%;
    padding-left: 5%;
  }
}
.check-availability .SearchCategory {
  font-size: 0.9rem;
  padding-left: 2%;
}
@media (min-width: 768px) {
  .check-availability .SearchCategory {
    width: 28.33%;
    left: 46.67%;
  }
}
.check-availability .SearchButton {
  border-top: 1px solid #ffaf56;
  background-color: #ff8500;
  background: -webkit-gradient(linear, left top, left bottom, from(#ff8500), to(#ff6400));
  background: linear-gradient(to bottom, #ff8500, #ff6400);
  border-radius: 5px;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  font-family: 'Bjola', sans-serif;
  font-weight: normal;
  text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.3);
  font-size: 1rem;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .check-availability .SearchButton {
    width: 20%;
    left: 76.67%;
    bottom: 21.01%;
  }
}
@media (min-width: 768px) {
  .toplinks {
    position: relative;
    z-index: 1;
  }
  .toplinks__wrapper {
    background-color: #00a3ff;
    background: -webkit-gradient(linear, left top, left bottom, from(#00a3ff), to(#005bff));
    background: linear-gradient(to bottom, #00a3ff, #005bff);
    position: relative;
  }
  .toplinks__inner {
    padding: 0 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    position: relative;
    z-index: 1;
  }
  .toplinks__nav {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
  .toplinks__ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-bottom: 0;
    padding-left: 0;
  }
  .toplinks__li {
    text-align: center;
    list-style: none;
    position: relative;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
  }
  .toplinks__li.open .dropdown-toggle:focus {
    color: #fff;
  }
  .toplinks__link {
    font-family: 'Bjola', sans-serif;
    font-weight: normal;
    color: #FFF;
    background: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    padding: 20px 5px;
    white-space: nowrap;
    display: block;
    position: relative;
    font-size: 1.375rem;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
  .toplinks__link:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #b9d92f;
    background: -webkit-gradient(linear, left top, left bottom, from(#b9d92f), to(#84bc1b));
    background: linear-gradient(to bottom, #b9d92f, #84bc1b);
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    z-index: -1;
  }
  .toplinks__link:hover,
  .toplinks__link:focus,
  .toplinks__link.active {
    color: #fff;
  }
  .toplinks__link:hover:before,
  .toplinks__link:focus:before,
  .toplinks__link.active:before {
    opacity: 1;
  }
  .toplinks__dropdown {
    text-align: center;
    padding: 0;
    border: none;
    background-color: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
    z-index: 2;
    background-color: #00a3ff;
    background: -webkit-gradient(linear, left top, left bottom, from(#00a3ff), to(#005bff));
    background: linear-gradient(to bottom, #00a3ff, #005bff);
    min-width: 100%;
  }
  .toplinks__dropdown-li {
    display: block;
    width: 100%;
    margin-left: 0;
    border-radius: 0;
  }
  .toplinks__dropdown-link {
    font-weight: inherit !important;
    padding: 5px 10px !important;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: #fff !important;
    -webkit-transform-origin: center top;
            transform-origin: center top;
    position: relative;
    font-family: 'Hvd_comic_serif_pro', sans-serif;
    font-weight: normal !important;
    font-size: 1rem;
  }
  .toplinks__dropdown-link:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #b9d92f;
    background: -webkit-gradient(linear, left top, left bottom, from(#b9d92f), to(#84bc1b));
    background: linear-gradient(to bottom, #b9d92f, #84bc1b);
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    z-index: -1;
  }
  .toplinks__dropdown-link:hover,
  .toplinks__dropdown-link:focus,
  .toplinks__dropdown-link.active {
    background-color: transparent !important;
    color: #fff !important;
  }
  .toplinks__dropdown-link:hover:before,
  .toplinks__dropdown-link:focus:before,
  .toplinks__dropdown-link.active:before {
    opacity: 1;
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .toplinks__inner {
    width: calc(100% + 50px);
    left: -25px;
  }
}
.badges {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.badges__badge {
  -ms-flex-item-align: center;
      align-self: center;
  margin: 0 10px;
}
.home-galleries {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (min-width: 768px) {
  .home-galleries {
    width: 100%;
    z-index: 1;
  }
}
.home-galleries .carousel {
  width: 100%;
  padding-top: 87.72%;
  overflow: hidden;
}
.home-galleries .carousel-inner {
  position: absolute;
  top: 0;
  left: 0;
}
.home-galleries .carousel-inner .item {
  width: 100%;
  height: 100%;
}
.home-galleries__gallery {
  border-radius: 5px;
  position: relative;
  margin-top: 10px;
  width: calc(33.33% - 10px);
}
@media (max-width: 500px) {
  .home-galleries__gallery {
    width: calc(50% - 10px);
  }
}
.home-galleries__gallery-title {
  color: #5a5a5a;
  font-family: 'Bjola', sans-serif;
  font-weight: normal;
  font-size: 1.3rem;
  margin: 15px auto 30px;
}
@media (max-width: 767px) {
  .home-galleries__gallery-title {
    padding: 15px 5px;
    background: #fff;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}
.main-content {
  padding: 30px 0;
}
@media (max-width: 767px) {
  .main-content {
    padding-top: 0;
  }
}
.main-content__wrapper {
  text-align: center;
}
.main-content__row {
  padding-top: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 767px) {
  .main-content__row {
    padding-top: 0;
  }
}
.main-content__sidebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.left-block {
  margin-top: auto;
}
.clients-gallery {
  text-align: center;
  position: relative;
}
.clients-gallery__title {
  font-family: 'Bjola', sans-serif;
  font-weight: normal;
  font-size: 40px;
  color: #2e93fd;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  background: #fff;
  padding: 0 20px;
  z-index: 1;
}
.clients-gallery__border {
  background: #fff;
  display: block;
  position: relative;
  padding: 20px 40px 15px;
  background: transparent;
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  padding-top: 50px;
  margin: 0px 30px;
}
.clients-gallery__arrow {
  position: absolute;
  width: 10%;
  bottom: 35%;
  border: none;
  background: transparent;
}
.clients-gallery__arrow--left {
  left: -5%;
}
.clients-gallery__arrow--right {
  right: -5%;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.clients-gallery .thumbnail {
  border: none;
}
.clients-gallery__link {
  display: block;
  border-radius: 5px;
  overflow: hidden;
  padding: 0;
  margin: 0 auto 10px !important;
  border-radius: 0 !important;
  background-color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.clients-gallery__link img {
  left: 0 !important;
  top: 0 !important;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 767px) {
  .clients-gallery__link {
    width: 300px !important;
    height: 200px;
  }
}
.category-links {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}
.category-links__link,
.category-links__child-link {
  position: relative;
  display: block;
  margin-bottom: 10px;
  text-align: left;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: 700;
  text-shadow: 1px 1px 2px #000;
  color: white;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-size: 100% 100%;
  border-radius: 8px;
  padding: 12px 15px;
  overflow: hidden;
  text-shadow: 1px 2px 0 rgba(0, 0, 0, 0.5);
}
.category-links__link:before,
.category-links__child-link:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-left: 8px solid #fff;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.category-links__link:nth-of-type(5n + 1),
.category-links__child-link:nth-of-type(5n + 1) {
  background-color: #f64ca9;
  background: -webkit-gradient(linear, left top, left bottom, from(#f64ca9), to(#ee2a62));
  background: linear-gradient(to bottom, #f64ca9, #ee2a62);
}
.category-links__link:nth-of-type(5n + 1) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 1) + div .category-links__child-link {
  background-color: #f64ca9;
  background: -webkit-gradient(linear, left top, left bottom, from(#f64ca9), to(#ee2a62));
  background: linear-gradient(to bottom, #f64ca9, #ee2a62);
}
.category-links__link:nth-of-type(5n + 2),
.category-links__child-link:nth-of-type(5n + 2) {
  background-color: #f1a737;
  background: -webkit-gradient(linear, left top, left bottom, from(#f1a737), to(#e6601e));
  background: linear-gradient(to bottom, #f1a737, #e6601e);
}
.category-links__link:nth-of-type(5n + 2) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 2) + div .category-links__child-link {
  background-color: #f1a737;
  background: -webkit-gradient(linear, left top, left bottom, from(#f1a737), to(#e6601e));
  background: linear-gradient(to bottom, #f1a737, #e6601e);
}
.category-links__link:nth-of-type(5n + 3),
.category-links__child-link:nth-of-type(5n + 3) {
  background-color: #60cf5d;
  background: -webkit-gradient(linear, left top, left bottom, from(#60cf5d), to(#35a833));
  background: linear-gradient(to bottom, #60cf5d, #35a833);
}
.category-links__link:nth-of-type(5n + 3) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 3) + div .category-links__child-link {
  background-color: #60cf5d;
  background: -webkit-gradient(linear, left top, left bottom, from(#60cf5d), to(#35a833));
  background: linear-gradient(to bottom, #60cf5d, #35a833);
}
.category-links__link:nth-of-type(5n + 4),
.category-links__child-link:nth-of-type(5n + 4) {
  background-color: #00c5f4;
  background: -webkit-gradient(linear, left top, left bottom, from(#00c5f4), to(#0096ea));
  background: linear-gradient(to bottom, #00c5f4, #0096ea);
}
.category-links__link:nth-of-type(5n + 4) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 4) + div .category-links__child-link {
  background-color: #00c5f4;
  background: -webkit-gradient(linear, left top, left bottom, from(#00c5f4), to(#0096ea));
  background: linear-gradient(to bottom, #00c5f4, #0096ea);
}
.category-links__link:nth-of-type(5n + 5),
.category-links__child-link:nth-of-type(5n + 5) {
  background-color: #c575c6;
  background: -webkit-gradient(linear, left top, left bottom, from(#c575c6), to(#954198));
  background: linear-gradient(to bottom, #c575c6, #954198);
}
.category-links__link:nth-of-type(5n + 5) + div .category-links__child-link,
.category-links__child-link:nth-of-type(5n + 5) + div .category-links__child-link {
  background-color: #c575c6;
  background: -webkit-gradient(linear, left top, left bottom, from(#c575c6), to(#954198));
  background: linear-gradient(to bottom, #c575c6, #954198);
}
@media (min-width: 1200px) {
  .category-links__link,
  .category-links__child-link {
    font-size: 20px;
  }
}
.category-links__link:hover,
.category-links__child-link:hover,
.category-links__link:focus,
.category-links__child-link:focus,
.category-links__link.active,
.category-links__child-link.active {
  text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  color: #fff;
}
.category-links__link:hover:before,
.category-links__child-link:hover:before,
.category-links__link:focus:before,
.category-links__child-link:focus:before,
.category-links__link.active:before,
.category-links__child-link.active:before {
  opacity: 1;
}
.category-links__child-link {
  margin-left: 10px;
}
.castlePanel {
  width: 100%;
  border: none;
  padding: 10px;
  background-color: white;
  margin: 0 0 30px;
  text-align: center;
  -webkit-box-shadow: 0 0 0px rgba(0, 0, 0, 0.3);
          box-shadow: 0 0 0px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 5px;
  border: 1px solid #c5c5c5;
}
.castlePanel:hover {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}
.castlePanel:hover .castleCheckBook {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}
.castlePanel .castleTitlePanel {
  text-align: center;
  margin: 10px 0 0;
  padding: 0 5%;
}
.castlePanel .castleTitlePanel a {
  color: #404040;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 0.9rem;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.castlePanel .castleCheckBook {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #1fb2ff;
  background: -webkit-gradient(linear, left top, left bottom, from(#1fb2ff), to(#0084ff));
  background: linear-gradient(to bottom, #1fb2ff, #0084ff);
  height: auto;
  margin-top: 10px;
  min-width: 50%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-family: 'Bjola', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  color: #404040;
  padding: 0;
  margin-top: 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}
.castlePanel .castlePriceDefault span.oldprice,
.castlePanel .castlePriceDiscounted span.oldprice {
  font-size: 0.5rem;
}
.castlePanel .castlePriceDefault span.newprice,
.castlePanel .castlePriceDiscounted span.newprice {
  font-size: 0.8rem;
}
.castlePanel .castleSubtitle {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  color: #5f5f5f;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: -webkit-box;
  display: flex;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
#PageListings {
  margin: 30px -8px 15px -8px;
}
#PageListings > div {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
#PageListings .castlePanel {
  border: 1px solid #ccc;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}
#HomePageListings.row-less-padding {
  margin-left: -10px !important;
  margin-right: -10px !important;
}
#HomePageListings.row-less-padding > div {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
@media (max-width: 420px) {
  #HomePageListings > div,
  #PageListings > div {
    width: 100%;
  }
}
.DetailsTitle h1,
.DetailsTitle h2,
.DetailsMainImage {
  background-color: #2e3ed1;
}
.BookButtons {
  text-align: center;
}
.BookButtons .BookButton {
  width: 195px;
  margin-left: auto;
  float: none;
  margin: 0 10px 10px;
}
.BookButtons .CheckButton {
  width: 195px;
  margin-left: auto;
  float: none;
  margin: 0 10px 10px;
}
.footer {
  background-color: #000;
  color: #fff;
  padding: 25px 10px;
  position: relative;
}
@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .footer__flex-row-sm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.footer__BCN {
  display: block;
}
@media (max-width: 767px) {
  .footer__BCN {
    margin-top: 10px;
  }
}
@media (min-width: 768px) {
  .footer__BCN {
    text-align: right;
  }
}
.footer__BCN img {
  max-width: 180px !important;
}
.footer__links {
  text-align: center;
}
.footer__link {
  margin: 0 5px;
  color: #fff;
  display: inline-block;
}
.footer__link:hover {
  color: #fff;
}
@media (max-width: 767px) {
  .footer__link {
    padding: 8px 0;
  }
}
/*ANIMATIONS*/
.wow {
  visibility: hidden;
}
.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}
@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
            transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
  0%,
  40% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
            transform: perspective(400px) rotateX(-20deg);
  }
  60% {
    -webkit-transform: perspective(400px) rotateX(10deg);
            transform: perspective(400px) rotateX(10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotateX(-5deg);
            transform: perspective(400px) rotateX(-5deg);
  }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
}
@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
            transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
  0%,
  40% {
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  40% {
    -webkit-transform: perspective(400px) rotateX(-20deg);
            transform: perspective(400px) rotateX(-20deg);
  }
  60% {
    -webkit-transform: perspective(400px) rotateX(10deg);
            transform: perspective(400px) rotateX(10deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: perspective(400px) rotateX(-5deg);
            transform: perspective(400px) rotateX(-5deg);
  }
  to {
    -webkit-transform: perspective(400px);
            transform: perspective(400px);
  }
}
.flipInX {
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
          animation-name: flipInX;
}
@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.zoomIn {
  -webkit-animation-name: zoomIn;
          animation-name: zoomIn;
}
