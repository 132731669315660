@import "../Assets/Functions.less";
@import (inline) "../Assets/Utilities.css";
@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@tablet-only: ~"(min-width: 768px) and (max-width: 1299px)";
@desktop: ~"(min-width: 1200px)";
@mobileNavColor: #005bff;
@castleDetailsColor: #2e3ed1;
@cloudUrl: "https://files.bookingonline.co.uk/image/upload/f_auto/themes/021/";
/*#region fonts*/
.OpenSans, .BodyFont {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
}

.OpenSansBold {
	font-family: 'Open Sans', sans-serif;
	font-weight: 700;
}

.PTSansNarrowBold {
	font-family: 'PT Sans Narrow', sans-serif;
	font-weight: 700;
}

.font-HvdComicSerifPro;

.HvdComicSerifPro {
	font-family: 'Hvd_comic_serif_pro',sans-serif;
	font-weight: normal !important;
}

.font-Bjola;

.Bjola {
	font-family: 'Bjola', sans-serif;
	font-weight: normal;
}

html {
	font-size: 16px;
}

body {
	.BodyFont;
}

.nicefont {
	.OpenSansBold;
}

h1, h2, h3, h4 {
	.OpenSansBold;
}
/*#endregion fonts*/
.textContent {
	font-size: 1rem;

	li a {
		@media @mobile {
			padding: 8px 0;
			margin-bottom: 10px;
			display: inline-block;
		}
	}
}

.textContent h1, .lower-content h2 {
	.Bjola;
	text-align: center;
	color: #1fc8e0;
	font-size: 2.3rem;
}

.textContent h1 {
	margin-top: 0;
}

#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: @mobileNavColor;
	.Gradient(#81d546,#47b227);
	color: white;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;
	.BodyFont();

	.navbar {
		margin-bottom: 0;
	}

	@media @tablet {
		display: none !important;
	}

	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 14px 63px;
		text-align: right;
	}

	.navbar-toggle .icon-bar {
		background: white;
	}

	.row {
		padding-top: 10px;
	}

	.input-group {
		margin-bottom: 0;
	}
}

#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0 0;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}

.outer-wrapper {
	position: relative;
	max-width: 100vw;
	overflow: hidden;
	position: relative;

	@media @md {
		padding: 0 10px 0;
	}

	@media @desktop {
		padding: 0;
	}

	&--alt {
		.header {
			@media @tablet {
				padding-top: 23.52941176%;
			}

			&__container {
				background: #fff;
			}
		}

		.category-links {
			margin-top: 0;
		}
	}
}

.sea-bg {
	position: absolute;
	bottom: 0;
	left: 0;
}

.bottom-sand {
	width: ~'calc(100% + 30px)';
	transform: translateX(-15px);
	max-width: none !important;
}

.container {
	position: relative;
	width: 100%;

	@media @desktop {
		width: 1170px;
		margin: 0 auto;
	}
}

.header {
	@width: 1190;
	@height: 280;
	text-align: center;
	position: relative;

	@media @mobile {
		padding-top: 80px;
		padding-bottom: 20px;
	}

	@media @tablet {
		padding-top: percentage(@height/@width);
	}

	&__wrapper {
		position: relative;
	}

	&__container {
	}

	&__logo {
		@media @mobile {
			display: block;
			margin: 0 auto 30px;
		}

		@media @tablet {
			position: absolute;
			width: 30.92%;
			bottom: 6.43%;
			left: 34.41%;
		}
	}

	&__call {
		@media @mobile {
			display: block;
			margin: 15px auto 0;
		}

		@media @tablet {
			position: absolute;
			width: 25.21%;
			bottom: 51.43%;
			right: 1.01%;
		}
	}

	&__phone {
		text-align: right;

		@media @mobile {
			margin: 15px auto 0;
		}

		@media @tablet {
			position: absolute;
			width: 29.41%;
			bottom: 34.57%;
			right: .42%;
		}
	}

	&__email {
		text-align: right;

		@media @mobile {
			display: block;
			margin: 15px auto 0;
		}

		@media @tablet {
			position: absolute;
			width: 21.01%;
			bottom: 13.43%;
			right: .42%;
		}
	}

	&__follow {
		.TopBarItem(300,@width,@height,auto,163,left,12);

		@media @mobile {
			margin: 15px auto 0;
		}
	}

	&__social {
		.TopBarItem(323,@width,@height,auto,65,left,20);
		display: flex;
		justify-content: space-between;

		@media @mobile {
			justify-content: center;
		}
	}

	&__social-link {
		display: inline-flex;
		width: 20%;

		@media @mobile {
			width: 75px;
			margin: 0 5px;
		}
	}
}

.banner {
	.BannerPanelSet(41.87%);

	&__container {
	}

	&__row {
		@media @mobile {
			display: flex;
			flex-direction: column;
		}
	}
}

.no-gutter {
	margin-left: 0;
	margin-right: 0;

	& > [class*='col-'] {
		padding-right: 0;
		padding-left: 0;
	}
}

.product-search {
	.Gradient(#bfe600,#a3c400);
	box-shadow: inset 0 -6px 0 0 rgba(0, 0, 0, 0.2);
	position: relative;
	z-index: 1;

	@media @mobile {
		padding: 20px !important;
		margin-top: 10px;
		margin-bottom: 10px;
		border-radius: 10px;
		margin-left: 10px;
		margin-right: 10px;
		width: ~'calc(100% - 20px)';
		text-align: center;
	}

	&:before {
		@media @tablet {
			content: "";
			display: block;
			width: 100%;
			padding-top: 50%;
		}
	}

	&:after {
		.StickToEdges;
		border-right: 1px solid rgba(0,0,0,0.1);
		pointer-events: none;
	}

	.SearchBoxTextBox {
		border-radius: 8px;
		border: none;
		box-shadow: 1px 1px 2px rgba(0,0,0,.3);

		@media @mobile {
			height: 40px;
			border-radius: 8px;
			width: 100%;
			margin: 0 auto;
			max-width: 430px !important;
			text-align: center;
		}

		@media @tablet {
			position: absolute;
			width: round(percentage(235/296),2);
			left: round(percentage(40/296),2);
			top: round(percentage(67/130),2);
			transition: all 0.3s;
			height: 27.33%;
			padding-left: 15%;
			z-index: 1;
		}
	}

	&__text {
		z-index: 1;

		@media @mobile {
			margin: 0 auto 15px;
		}

		@media @tablet {
			position: absolute;
			width: 39.70%;
			left: 41.89%;
			top: 16.15%;
		}
	}

	&__icon {
		z-index: 1;

		@media @mobile {
			display: none;
		}

		@media @tablet {
			position: absolute;
			width: round(percentage(106/296),2);
			left: round(percentage(5/296),2);
			top: round(percentage(11/130),2);
			transition: all 0.3s;
		}
	}
}

.check-area {
	.Gradient(#bfe600,#a3c400);
	box-shadow: inset 0 -6px 0 0 rgba(0, 0, 0, 0.2);
	position: relative;
	z-index: 1;

	@media @mobile {
		padding: 20px !important;
		margin-bottom: 10px;
		border-radius: 10px;
		margin-left: 10px;
		margin-right: 10px;
		width: ~'calc(100% - 20px)';
	}

	&:before {
		@media @tablet {
			content: "";
			display: block;
			width: 100%;
			padding-top: 50%;
		}
	}

	&:after {
		.StickToEdges;
		border-right: 1px solid rgba(0,0,0,0.1);
	}

	&__inner {
		@media @mobile {
			max-width: 450px;
			margin: 0 auto;
		}
	}

	&__text {
		@media @mobile {
			max-width: ~'calc(100% - 10px)' !important;
			margin: 0 auto;
			display: block;
		}

		@media @tablet {
			position: absolute;
			width: 73.63%;
			left: 23.52%;
			top: 16.15%;
		}
	}

	&__icon {
		z-index: 2;

		@media @mobile {
			max-width: 50px !important;
			display: none;
		}

		@media @tablet {
			position: absolute;
			width: round(percentage(216/630),2);
			left: round(percentage(8/630),2);
			top: round(percentage(59/300),2);
		}
	}

	#PostcodeSearchLeft select, #PostcodeSearchLeft input {
		z-index: 1;
		font-size: 0.9rem;

		@media @mobile {
			height: 40px;
			max-width: 430px !important;
			text-align: center;
			margin: 10px auto 0;
			text-align: center;
			position: relative;
			z-index: 1;
		}

		@media @tablet {
			position: absolute;
			width: 75%;
			top: 50%;
			left: 19%;
			height: 27.33%;
			padding-left: 15%;
		}

		border-radius: 8px;
		border: none;
		box-shadow: 1px 1px 2px rgba(0,0,0,0.3);
	}

	.PostcodeMessage {
		transform: translateY(100%);
	}
}

.check-availability {
	.Gradient(#bfe600,#a3c400);
	box-shadow: inset 0 -6px 0 0 rgba(0, 0, 0, 0.2);
	border-bottom-right-radius: 10px;
	position: relative;
	z-index: 2;

	@media @mobile {
		padding: 20px !important;
		border-radius: 10px;
		margin-left: 10px;
		margin-right: 10px;
		width: ~'calc(100% - 20px)';
	}

	&:before {
		@media @tablet {
			content: "";
			display: block;
			width: 100%;
			padding-top: 25%;
		}
	}

	&__inner {
		@media @mobile {
			max-width: 450px;
			margin: 0 auto;
		}
	}

	&__title {
		@media @mobile {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	&__text {
		@media @mobile {
			max-width: ~'calc(100% - 10px)' !important;
			margin: 0 auto;
		}

		@media @tablet {
			position: absolute;
			width: 72.22%;
			left: 20.67%;
			top: 16.15%;
		}
	}

	&__icon {
		z-index: 2;

		@media @mobile {
			max-width: 45px !important;
			display: none;
			margin: 0 auto 15px;
		}

		@media @tablet {
			position: absolute;
			width: round(percentage(153/1200),2);
			left: round(percentage(51/1200),2);
			top: round(percentage(80/300),2);
		}
	}

	.SearchDate, .SearchCategory, .SearchButton {
		height: 40px;
		display: block;
		border: none;
		border-radius: 8px;
		box-shadow: 1px 1px 2px rgba(0,0,0,0.3);

		@media @mobile {
			width: 100%;
			margin: 15px auto 0;
			max-width: 430px !important;
			text-align: center;
		}

		@media @tablet {
			position: absolute;
			top: 50%;
			left: 19%;
			height: 27.33%;
		}
	}

	.SearchDate {
		font-size: 0.9rem;

		@media @tablet {
			width: round(percentage(360/1200),2);
			left: round(percentage(180/1200),2);
			padding-left: 5%;
		}
	}

	.SearchCategory {
		font-size: 0.9rem;
		padding-left: 2%;

		@media @tablet {
			width: round(percentage(340/1200),2);
			left: round(percentage(560/1200),2);
		}
	}

	.SearchButton {
		@media @tablet {
			width: round(percentage(240/1200),2);
			left: round(percentage(920/1200),2);
			bottom: 21.01%;
		}

		border-top: 1px solid #ffaf56;
		.Gradient(#ff8500,#ff6400);
		border-radius: 5px;
		.FlexCenter;
		color: #fff;
		.Bjola;
		text-shadow: 0px 2px 1px rgba(0,0,0,0.3);
		font-size: 1rem;
		text-transform: uppercase;
	}
}

@media @tablet {
	.toplinks {
		position: relative;
		z-index: 1;

		&__wrapper {
			.Gradient(#00a3ff,#005bff);
			position: relative;
		}

		&__container {
		}

		&__inner {
			padding: 0 10px;
			display: flex;
			justify-content: space-between;
			position: relative;
			position: relative;
			z-index: 1;

			@media @desktop {
				width: ~'calc(100% + 50px)';
				left: -25px;
			}
		}

		&__nav {
			flex-grow: 1;
		}

		&__ul {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-bottom: 0;
			padding-left: 0;
		}

		&__li {
			text-align: center;
			list-style: none;
			position: relative;
			.Transition3s();
			flex-grow: 1;

			&.open .dropdown-toggle:focus {
				color: #fff;
			}
		}

		&__link {
			.Bjola;
			color: #FFF;
			background: none;
			transition: all 0.3s;
			padding: 20px 5px;
			white-space: nowrap;
			display: block;
			position: relative;
			font-size: 1.375rem;
			text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
			z-index: 1;

			&:before {
				content: "";
				.StickToEdges;
				.Gradient(#b9d92f,#84bc1b);
				opacity: 0;
				transition: all 0.3s;
				z-index: -1;
			}

			&:hover, &:focus, &.active {
				color: #fff;

				&:before {
					opacity: 1;
				}
			}
		}

		&__dropdown {
			text-align: center;
			padding: 0;
			border: none;
			background-color: transparent;
			box-shadow: none;
			z-index: 2;
			.Gradient(#00a3ff,#005bff);
			min-width: 100%;
		}

		&__dropdown-li {
			display: block;
			width: 100%;
			margin-left: 0;
			border-radius: 0;
		}

		&__dropdown-link {
			font-weight: inherit !important;
			padding: 5px 10px !important;
			.Transition3s;
			color: #fff !important;
			transform-origin: center top;
			position: relative;
			.HvdComicSerifPro;
			font-size: 1rem;

			&:before {
				content: "";
				.StickToEdges;
				.Gradient(#b9d92f,#84bc1b);
				opacity: 0;
				transition: all 0.3s;
				z-index: -1;
			}

			&:hover, &:focus, &.active {
				background-color: transparent !important;
				color: #fff !important;

				&:before {
					opacity: 1;
				}
			}
		}
	}
}

.badges {
	display: flex;

	&__badge {
		align-self: center;
		margin: 0 10px;
	}
}

.home-galleries {
	position: relative;
	z-index: 1;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	@media @tablet {
		width: 100%;
		z-index: 1;
	}

	.carousel {
		width: 100%;
		padding-top: 87.72%;
		overflow: hidden;
	}



	.carousel-inner {
		position: absolute;
		top: 0;
		left: 0;



		.item {
			width: 100%;
			height: 100%;
		}
	}

	&__gallery {
		border-radius: 5px;
		position: relative;
		margin-top: 10px;
		width: ~'calc(33.33% - 10px)';

		@media (max-width:500px) {
			width: ~'calc(50% - 10px)';
		}
	}

	&__gallery-title {
		color: #5a5a5a;
		.Bjola;
		font-size: 1.3rem;
		margin: 15px auto 30px;

		@media @mobile {
			padding: 15px 5px;
			background: #fff;
			.border-bottom-radius(10px);
		}
	}
}

.main-content {
	padding: 30px 0;

	@media @mobile {
		padding-top: 0;
	}

	&__wrapper {
		text-align: center;
	}

	&__row {
		padding-top: 50px;
		display: flex;

		@media @mobile {
			padding-top: 0;
		}
	}

	&__sidebar {
		display: flex;
		flex-direction: column;
	}
}

.left-block {
	margin-top: auto;
}

.clients-gallery {
	text-align: center;
	position: relative;

	&__title {
		.Bjola;
		font-size: 40px;
		color: #2e93fd;
		display: inline-block;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate3d(-50%,-50%,0);
		background: #fff;
		padding: 0 20px;
		z-index: 1;
	}

	&__border {
		background: #fff;
		display: block;
		position: relative;
		padding: 20px 40px 15px;
		background: transparent;
		border: 1px solid #cdcdcd;
		border-radius: 10px;
		padding-top: 50px;
		margin: 0px 30px;
	}

	&__arrow {
		position: absolute;
		width: 10%;
		bottom: 35%;
		border: none;
		background: transparent;

		&--left {
			left: -5%;
		}

		&--right {
			right: -5%;
			transform: scaleX(-1);
		}
	}

	.thumbnail {
		border: none;
	}

	&__link {
		display: block;
		border-radius: 5px;
		overflow: hidden;
		padding: 0;
		margin: 0 auto 10px !important;
		border-radius: 0 !important;
		background-color: #fff;
		transition: all 0.3s;

		&:hover {
		}

		img {
			left: 0 !important;
			top: 0 !important;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		@media @mobile {
			width: 300px !important;
			height: 200px;
		}
	}
}

.category-links {
	margin-top: 20px;
	margin-bottom: 20px;
	position: relative;

	&__link, &__child-link {
		position: relative;
		display: block;
		margin-bottom: 10px;
		text-align: left;
		.PTSansNarrowBold;
		.TextShadow1();
		color: white;
		.Transition3s();
		background-size: 100% 100%;
		border-radius: 8px;
		padding: 12px 15px;
		overflow: hidden;
		text-shadow: 1px 2px 0 rgba(0,0,0,0.5);

		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			border-left: 8px solid #fff;
			border-top: 8px solid transparent;
			border-bottom: 8px solid transparent;
			opacity: 0;
			transition: opacity 0.3s;
		}

		&:nth-of-type(5n + 1) {
			.Gradient(#f64ca9,#ee2a62);

			+ div .category-links__child-link {
				.Gradient(#f64ca9,#ee2a62);
			}
		}

		&:nth-of-type(5n + 2) {
			.Gradient(#f1a737,#e6601e);

			+ div .category-links__child-link {
				.Gradient(#f1a737,#e6601e);
			}
		}

		&:nth-of-type(5n + 3) {
			.Gradient(#60cf5d,#35a833);

			+ div .category-links__child-link {
				.Gradient(#60cf5d,#35a833);
			}
		}

		&:nth-of-type(5n + 4) {
			.Gradient(#00c5f4,#0096ea);

			+ div .category-links__child-link {
				.Gradient(#00c5f4,#0096ea);
			}
		}

		&:nth-of-type(5n + 5) {
			.Gradient(#c575c6,#954198);

			+ div .category-links__child-link {
				.Gradient(#c575c6,#954198);
			}
		}

		@media @desktop {
			font-size: 20px;
		}

		&:hover, &:focus, &.active {
			text-shadow: 0 0 0 rgba(0,0,0,0);
			color: #fff;

			&:before {
				opacity:1;
			}
		}

	}

	&__child-link {
		margin-left: 10px;
	}
}

.castlePanel {
	width: 100%;
	border: none;
	padding: 10px;
	background-color: white;
	margin: 0 0 30px;
	text-align: center;
	box-shadow: 0 0 0px rgba(0,0,0,0.3);
	transition: all 0.3s;
	border-radius: 5px;
	border: 1px solid #c5c5c5;

	&:hover {
		box-shadow: 0 3px 6px rgba(0,0,0,0.3);

		.castleCheckBook {
			box-shadow: 0 3px 6px rgba(0,0,0,0.3);
		}
	}

	.castleTitlePanel {
		text-align: center;
		margin: 10px 0 0;
		padding: 0 5%;

		a {
			color: #404040;
			.OpenSansBold;
			font-size: 0.9rem;
			.FlexCenter;
		}
	}

	span.oldprice, span.newprice {
	}

	.castleCheckBook {
		padding: 10px 20px;
		border-radius: 5px;
		.Gradient(#1fb2ff,#0084ff);
		height: auto;
		margin-top: 10px;
		min-width: 50%;
		transition: all 0.3s;
		.Bjola;
		text-transform: uppercase;
		text-shadow: 0 2px 2px rgba(0,0,0,.3);
	}

	.castlePriceDefault, .castlePriceDiscounted {
		color: #404040;
		padding: 0;
		margin-top: 10px;
		.OpenSansBold;

		span.oldprice {
			font-size: 0.5rem;
		}

		span.newprice {
			font-size: 0.8rem;
		}
	}

	.castleSubtitle {
		.OpenSans;
		font-size: 0.8rem;
		color: #5f5f5f;
		.FlexCenter;
	}
}

#PageListings {
	margin: 30px -8px 15px -8px;

	& > div {
		padding-left: 8px !important;
		padding-right: 8px !important;
	}

	.castlePanel {
		border: 1px solid #ccc;
		box-shadow: 0 3px 6px rgba(0,0,0,0.3);
	}
}

#HomePageListings.row-less-padding {
	margin-left: -10px !important;
	margin-right: -10px !important;

	& > div {
		padding-left: 10px !important;
		padding-right: 10px !important;
	}
}

#HomePageListings, #PageListings {
	& > div {
		@media (max-width:420px) {
			width: 100%;
		}
	}
}

.DetailsTitle h1, .DetailsTitle h2, .DetailsMainImage {
	background-color: @castleDetailsColor;
}

.BookButtons {
	text-align: center;

	.BookButton {
		width: 195px;
		margin-left: auto;
		float: none;
		margin: 0 10px 10px;
	}

	.CheckButton {
		width: 195px;
		margin-left: auto;
		float: none;
		margin: 0 10px 10px;
	}
}

.footer {
	background-color: #000;
	color: #fff;
	padding: 25px 10px;
	position: relative;

	@media @mobile {
		text-align: center;
	}

	&__flex-row-sm {
		@media @sm {
			display: flex;
			align-items: center;
		}
	}

	&__BCN {
		display: block;

		@media @mobile {
			margin-top: 10px;
		}

		@media @sm {
			text-align: right;
		}

		img {
			max-width: 180px !important;
		}
	}

	&__links {
		text-align: center;
	}

	&__link {
		margin: 0 5px;
		color: #fff;
		display: inline-block;

		&:hover {
			color: #fff;
		}

		@media @mobile {
			padding: 8px 0;
		}
	}
}
/*ANIMATIONS*/
.wow {
	visibility: hidden;
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: both
}

@keyframes fadeIn {
	0% {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

.fadeIn {
	animation-name: fadeIn
}


@keyframes flipInX {
	0% {
		transform: perspective(400px) rotateX(90deg);
		opacity: 0
	}

	0%,40% {
		animation-timing-function: ease-in
	}

	40% {
		transform: perspective(400px) rotateX(-20deg)
	}

	60% {
		transform: perspective(400px) rotateX(10deg);
		opacity: 1
	}

	80% {
		transform: perspective(400px) rotateX(-5deg)
	}

	to {
		transform: perspective(400px)
	}
}

.flipInX {
	backface-visibility: visible !important;
	animation-name: flipInX
}

@keyframes zoomIn {
	0% {
		opacity: 0;
		transform: scale3d(.3,.3,.3)
	}

	50% {
		opacity: 1
	}
}

.zoomIn {
	animation-name: zoomIn
}
